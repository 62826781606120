import React, { useState, useEffect } from 'react'
import axios from 'axios';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { Layout, Divider, Row, Col, Table, Button, Popconfirm, Form, Input, InputNumber, notification } from 'antd';
import { menusModify } from '../../service/http'
import { SUCCEED, ADD, CANCEL, EDIT, DELETE, SAVE } from '../../utils/constants'


const EditableContext = React.createContext();

axios.defaults.withCredentials = true

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const getInput = () => {
        if (inputType === "number") {
            return <InputNumber />;
        }
        return <Input />;
    };

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {getInput()}
                </Form.Item>
            ) : (
                    children
                )}
        </td>
    );
};

const Home = (props) => {

    const [form] = Form.useForm();
    const [data, setData] = useState([{ name: "sth" }])
    const [editingKey, setEditingKey] = useState("")
    const columns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
            editable: true,
            render: text => <Button type='link'>{text}</Button>
        },
        {
            title: 'en',
            dataIndex: 'en',
            key: 'en',
            editable: true,
            render: text => <Button type='link'>{text}</Button>
        },
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'display',
            dataIndex: 'display',
            key: 'display'
        },
        {
            title: 'pid',
            key: 'pid',
            dataIndex: 'pid',
            editable: true,
        },
        {
            title: 'type',
            key: 'type',
            dataIndex: 'type',
        },
        {
            title: 'url',
            key: 'url',
            editable: true,
            dataIndex: 'url',
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a
                            href="javascript:;"
                            onClick={() => save(record.key)}
                            style={{
                                marginRight: 8,
                            }}
                        >{SAVE} </a>

                        <Popconfirm
                            title="Sure to cancel?"
                            onConfirm={cancel}
                        > <a>{CANCEL}</a>
                        </Popconfirm>
                    </span>
                ) : (
                        <span>
                            <a
                                disabled={editingKey !== ""}
                                onClick={() => edit(record)}
                                style={{
                                    marginRight: 8,
                                }}
                            >
                                {EDIT}
                            </a>
                            {/* <a
                                disabled={editingKey !== ""}
                                onClick={() => handleDelete(record)}
                            >
                                {DELETE}
                            </a> */}
                        </span>
                    );
            },
        }
    ]
    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            ...record
        });
        setEditingKey(record.key);
    }
    const save = async key => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex(item => key === item.key);

            if (index > -1) {
                const item = newData[index];
                menusModify(newData, row, index, item).then(res => {
                    if(!res) return 
                    if (res.data.code === 0) {
                        newData.splice(index, 1, { ...item, ...row, })
                        notification.open({
                            message: "修改成功",
                            duration: 3,
                        });
                        setEditingKey('')
                    }
                })
            }
        } catch (err) { console.error(err) }
    }

    const cancel = () => {
        setEditingKey("");
    };
    const handleDelete = key => {
        const data = [...this.state.data];
        this.setState({
            data: data.filter(item => item.key !== key),
        });
    };
    useEffect(() => {
        getMenuList()
    }, [])
    const getMenuList = () => {
        axios.get("/website/menus/list", {
            params: {
                lang: "en"
            }
        }).then(res => {
            if(!res) return 
            const results = res.data.data.result.map((row, index) => ({
                key: index, // I added this line
                name: row.name,
                type: row.type,
                dataIndex: index,
                display: row.display,
                id: row.id,
                langName: row.langName,
                en: row.en,
                pid: row.pid,
                url: row.url
            }))
            setData(results)
            // console.log("======componentDidMount-results:" + JSON.stringify(results))
        })
            .catch(function (error) {
                console.log(error)
            })
    }
    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    const components = {
        body: {
            cell: EditableCell,
        },
    };
    return (
        <Layout>
            <Row>
                <Col><h3 id='basic'>导航菜单管理</h3></Col>
                <Col><Layout><Divider />
                    <Form form={form} component={false}>
                        <Table
                            columns={mergedColumns}
                            dataSource={data}
                            rowClassName="editable-row"
                            components={components}
                        />
                    </Form >
                </Layout>
                </Col>
            </Row>
        </Layout>
    )
}
export default Home
