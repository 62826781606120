import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import axios from "axios";
import {
  List,
  Upload,
  Button,
  Row,
  Col,
  Avatar,
  Popconfirm,
  notification,
} from "antd";
import { SUBMIT, SELECT, DELETE, ADD, baseUrl } from "../../utils/constants";
import UploadModalForm from "./uploadModal";

function Robotpage(props) {
  const id = props.match.params.id;
  const robotid = parseInt(id);

  const [fileList, setFileList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState([]);
  const [dataEn, setDataEn] = useState([]);
  const [visible, setModalVisble] = useState(false);

  const [
    { data: getData, loading: getLoading, error: getError },
    getRobotPictures,
  ] = useAxios(
    {
      url: `/website/robots/${robotid}/pictures`,
      method: "GET",
    },
    { manual: true }
  );

  const [{ data: putData, error: putError }, putRobotPicture] = useAxios({
    url: `/website/robots/${robotid}/pictures`,
    method: "PUT",
  });

  const updateDataList = () => {
    getRobotPictures().then((res) => {
      console.log(JSON.stringify(res.data.data.result));
      let result = res.data.data.result.map((item) => {
        let obj = {};
        obj["id"] = item.id;
        obj["sid"] = item.sid;
        obj["path"] = item.path + item.file;
        obj["lang"] = item.lang;
        obj["type"] = item.type;
        return obj;
      });

      setDataList(result);
      setData(result.filter((item) => item.lang !== "en"));
      setDataEn(result.filter((item) => item.lang === "en"));
    });
  };

  useEffect(() => {
    updateDataList();
  }, []);

  const handleChange = (info) => {
    console.log("fileList", info.fileList);
    setFileList(info.fileList);
  };

  const uProps = {
    beforeUpload: () => false,
    onChange: handleChange,
    multiple: false,
    bordered: true,
    loading: true,
  };

  const handleSubmit = (sid, lang, index = 999, type) => {
    console.log("===handleSubmit:", sid);
    let formData = new FormData();
    // let type = index == 0 ? 1 : 2
    formData.append("robotid", robotid);
    formData.append("sid", sid);
    formData.append("type", type);
    formData.append("lang", lang);
    formData.append("file", fileList[0].originFileObj);
    axios({
      url: `/website/robots/${robotid}/pictures`,
      method: "PUT",
      data: formData,
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      let result = res.data.data.result;
      console.log(JSON.stringify(res.data.data.result));
      console.log(JSON.stringify(data));
      console.log(JSON.stringify(dataEn));
      updateDataList();
    });
  };

  const handleDeleteEach = (pictureid) => {
    console.log("====handleDelete", pictureid);
    axios
      .delete(`/website/robots/${robotid}/pictures/${pictureid}`, {
        robotid: robotid,
        pictureid: pictureid,
      })
      .then((res) => {
        updateDataList();
      });
  };

  const uploadSucceed = () => {
    notification.open({
      message: "添加成功",
      duration: 3,
    });
    setModalVisble(false);
  };

  return (
    <div>
      <h1>
        左栏为中文版图片，右栏为英文版图片，请对应更改上传，中英都是英文版的题图，中文版题图可省略
      </h1>
      <Row gutter={24}>
        <Col span={2}></Col>
        <Col span={8}>
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      shape="square"
                      size={128}
                      src={baseUrl + "/" + item.path}
                    />
                  }
                  description={item.sid}
                />

                <Upload {...uProps} fileList={fileList} onChange={handleChange}>
                  <Button>{SELECT}</Button>
                </Upload>
                <Button
                  onClick={() =>
                    handleSubmit(item.sid, item.lang, index, item.type)
                  }
                >
                  {SUBMIT}
                </Button>
                <Popconfirm
                  title="确定删除?"
                  onConfirm={() => handleDeleteEach(item.id)}
                >
                  <Button>{DELETE}</Button>
                </Popconfirm>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>

        <Col span={8}>
          <List
            itemLayout="horizontal"
            dataSource={dataEn}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      shape="square"
                      size={128}
                      src={baseUrl + "/" + item.path}
                    />
                  }
                  description={item.sid}
                />
                <Upload {...uProps} fileList={fileList} onChange={handleChange}>
                  <Button>{SELECT}</Button>
                </Upload>
                <Button
                  onClick={() =>
                    handleSubmit(item.sid, item.lang, index, item.type)
                  }
                >
                  {SUBMIT}
                </Button>
                <Popconfirm
                  title="确定删除?"
                  onConfirm={() => handleDeleteEach(item.id)}
                >
                  <Button>{DELETE}</Button>
                </Popconfirm>
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}></Col>
      </Row>
      <UploadModalForm
        visible={visible}
        robotid={robotid}
        updateDataList={updateDataList}
        uploadSucceed={uploadSucceed}
      />
      <Button
        onClick={() => setModalVisble(true)}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        {ADD}
      </Button>
    </div>
  );
}

export default Robotpage;
