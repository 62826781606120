import React, { Component } from "react";
import { Modal, Input, Form, Button, Divider, notification } from "antd";
import { withRouter } from "react-router-dom";
import Icon from "@ant-design/icons";
import "../../style/view-style/login.scss";
import { http, userLogin } from "../../service/http";
class Login extends Component {
  state = {
    loading: false,
    visible: true,
  };

  enterLoading = () => {
    this.setState({
      loading: true,
    });
  };

  handleSubmit = (values) => {
    // this.props.form.validateFields((err, values) => {
    let { username, password } = values;
    http(userLogin(username, password));
    this.props.onOk();
    // console.log(this.props);
    // this.props.history.push("home");
  };

  componentDidMount() {
    notification.open({
      message: "欢迎使用reemancn后台管理平台",
      duration: 1,
      description: "账号reeman 密码 *** ",
    });
  }

  componentWillUnmount() {
    notification.destroy();
    this.timer && clearTimeout(this.timer);
  }

  onOk = () => {
    this.props.onOk();
  };

  render() {
    return (
      <Modal
        // visible={this.props.visible}
        visible={window.location.pathname == "/" ? true : this.props.visible}
        onOk={this.onOk}
        closable={false}
        maskClosable={false}
        keyboard={false}
        footer={[]}
      >
        <div className="model">
          <div className="login-form">
            <h3>reeman.cn后台管理系统</h3>
            <Divider />
            <Form onFinish={this.handleSubmit}>
              <Form.Item
                name="username"
                rules={[{ required: true, message: "请输入用户名!" }]}
              >
                <Input
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="用户名"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "请输入密码" }]}
              >
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="密码"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={this.state.loading}
                >
                  登录
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withRouter(Login);
