import React, { Component } from 'react'
import { Table, Popconfirm, notification, Button } from 'antd';
import { Upload, Tabs } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ADD, REPLACE, SELECT, DELETE } from '../../utils/constants'
import UploadModal from '../../components/uploadmodal'
import { updatePicture, deletePicture, getPicturesByMenu } from '../../service/request';
const { TabPane } = Tabs;
const data = [
    {
        name: 'menuid',
        value: '',
        label: 'menuid',
        required: true,
        type: 'number',
    },
    {
        name: 'moduleId',
        value: '',
        label: 'moduleId',
        required: true,
        type: 'number',
    },
    {
        name: 'sid',
        label: 'sid',
        value: '',
        required: false,
        type: 'number',
    },
    {
        name: 'lang',
        value: '',
        label: 'lang',
        required: true,
        type: 'string',
    },
    {
        name: 'url',
        label: 'url',
        value: '',
        required: false,
        type: 'string',
    },

]
class Pictures extends Component {
    constructor() {
        super()
        this.state = {
            pictures: [],
            visible: false,
            fileList: [],
            menupage: 1
        }
        const prop = {
            onChange: this.handleChange,
            multiple: false,
        };

        this.columns = [
            {
                title: '图片',
                dataIndex: 'img',
                key: 'img',
                width: "20px",
                height: "20px",
                render: (text) => <img style={{
                    width: 160,
                    height: 100
                }} src={text} />
            },
            {
                title: 'path',
                dataIndex: 'path',
                key: 'path'
            },
            {
                title: 'lang',
                dataIndex: 'lang',
                key: 'lang'
            },
            {
                title: 'menuid',
                dataIndex: 'menuid',
                key: 'menuid'
            },
            {
                title: 'moduleId',
                dataIndex: "moduleId",
                key: "moduleId"
            },
            {
                title: 'id',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: 'sid',
                dataIndex: 'sid',
                key: 'sid'
            },
            {
                title: 'url',
                dataIndex: 'url',
                key: 'url'
            },
            {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    return (
                        <span>
                            <Upload
                                {...prop}
                                fileList={this.state.fileList}
                                beforeUpload={() => false}
                            >
                                <Button>
                                    <UploadOutlined /> {SELECT}
                                </Button>
                            </Upload>
                            <Button onClick={(e) => this.handleReplace(e, this.uploadProgress, record)} >
                                {REPLACE}
                            </Button>
                            <Popconfirm
                                title="确定删除?" onConfirm={() => this.handleDelete(record)}>
                                <Button>
                                    {DELETE}
                                </Button>
                            </Popconfirm>
                        </span>
                    )
                }
            }

        ]

        console.log('under strict mode: ', this.test);
    }

    handleChange = ({ fileList }) => {
        console.log('fileList', fileList);
        this.setState({ fileList });
    };

    getPictures = () => {
        const { menupage } = this.state
        // default request the 1st page
        getPicturesByMenu(menupage).then(res => {
            if (!res) return
            const data = res.data.data.result
            if (res.data.msg === "success") {
                const results = data.map((row, index) => ({
                    key: index,
                    id: row.id,
                    menuid: row.menuId,
                    moduleId: row.moduleId,
                    sid: row.sid,
                    path: row.path,
                    file: row.file,
                    img: row.path + row.file,
                    lang: row.lang,
                    url: row.url
                }))
                this.setState({
                    pictures: results
                })
            }
        })
    }

    componentDidMount() {
        this.getPictures()
    }

    handleSubmit = (values, fileList) => {
        const { menupage } = this.state
        let formData = new FormData();
        formData.append("file", fileList[0].originFileObj);
        formData.append('menuid', values.menuid)
        formData.append('moduleId', values.moduleId)
        formData.append("sid", values.sid)
        formData.append("lang", values.lang)
        formData.append("url", values.url)

        updatePicture(menupage, formData).then(res => {
            this.getPictures()
        })
    }
    // /website/pictures/delete
    handleDelete = record => {
        const { menupage } = this.state
        deletePicture(menupage, record.id)
            .then(res => {
                if (!res) return
                if (res.data.msg === "success") {
                    notification.open({
                        message: '删除成功',
                        duration: 3,
                    })

                    this.setState({
                        pictures: this.state.pictures.filter(item => item.key !== record.key),
                    })
                }
            })
            .catch(function (response) {
                console.log(response);
            });
    }

    handleReplace = (e, cb, record) => {
        const { menupage } = this.state
        e.preventDefault();

        let formData = new FormData();
        formData.append("file", this.state.fileList[0].originFileObj);
        formData.append('menuid', record.menuid)
        formData.append('moduleId', record.moduleId)
        formData.append("sid", record.sid)
        formData.append("lang", record.lang)
        formData.append("url", record.url)

        updatePicture(menupage, formData)
            .then(res => {
                if (!res) return
                if (res.data.msg === "success") {
                    notification.open({
                        message: '上传成功',
                        duration: 3,
                    })
                    this.getPictures()
                }
            }).catch(function (response) {
                console.log(response);
            });
    }

    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };


    onChange = (key) => {
        this.setState({ menupage: key }, () => {
            this.getPictures()
        })
    }

    render() {
        const { pictures } = this.state

        return (
            <div>
                <Tabs defaultActiveKey="1" onChange={this.onChange}>
                    <TabPane tab="主页" key="1" value={1}>
                    </TabPane>
                    <TabPane tab="Page 2" key="2" value={2}>
                    </TabPane>
                    <TabPane tab="Page 3" key="3" value={3}>
                    </TabPane>
                </Tabs>,
                <Table
                    size={"small"}
                    columns={this.columns}
                    dataSource={pictures}
                    pagination={{
                        hideOnSinglePage: true,
                        defaultPageSize: 5
                    }}
                    rowSelection={this.rowSelection}
                />
                <UploadModal
                    data={data}
                    handleSubmit={this.handleSubmit}
                    ifUploadFile={true}
                >
                    <Button>
                        {ADD}
                    </Button>
                </UploadModal>
            </div>
        )
    }
}

export default Pictures