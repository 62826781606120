import React, { Component, useState, useEffect } from "react";
import {
  Table,
  Button,
  Popconfirm,
  Form,
  InputNumber,
  Input,
  Modal,
  notification,
} from "antd";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  SUCCEED,
  ADD,
  CANCEL,
  EDIT,
  SAVE,
  DELETE,
} from "../../utils/constants";
import {
  getRobots,
  modifyRobot,
  createRobot,
  deleteRobot,
} from "../../service/request";

axios.defaults.withCredentials = true;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const getInput = () => {
    if (inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {getInput()}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Robots = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([{ name: "sth" }]);
  const [editingKey, setEditingKey] = useState("");
  const [visible, setVisible] = useState(false);
  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      editable: true,
    },
    {
      title: "enName",
      dataIndex: "enName",
      key: "enName",
      editable: true,
    },
    {
      title: "id",
      dataIndex: "id",
      key: "pid",
      editable: false,
    },
    {
      title: "type",
      dataIndex: "type",
      key: "type",
      editable: true,
    },
    {
      title: "description",
      dataIndex: "description",
      key: "description",
      editable: true,
    },
    {
      title: "enDescription",
      dataIndex: "enDescription",
      key: "enDescription",
      editable: true,
    },
    {
      title: "url",
      dataIndex: "url",
      key: "url",
      editable: true,
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              {SAVE}
            </a>

            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              {" "}
              <a>{CANCEL}</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <a
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
              style={{
                marginRight: 8,
              }}
            >
              {EDIT}
            </a>
            <a
              disabled={editingKey !== ""}
              onClick={() => handleDelete(record)}
            >
              {DELETE}
            </a>
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    getRobotsList();
  }, []);

  const getRobotsList = () => {
    getRobots()
      .then((res) => {
        if (!res) return;
        const results = res.data.data.result.map((row, index) => ({
          key: index,
          name: row.name,
          type: row.type,
          dataIndex: index,
          id: row.id,
          pid: row.pid,
          description: row.description,
          enDescription: row.enDescription,
          url: row.url,
          enName: row.enName,
        }));
        setData(results);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey("");

        console.log("**************item" + JSON.stringify(row));
        console.log(
          "**************row.id" +
            newData[index].id +
            row.description +
            row.type
        );
        //
        let args = {
          id: newData[index].id,
          name: row.name,
          type: row.type,
          url: row.url,
          description: row.description,
          langDescription: row.enDescription,
          langName: row.enName,
          lang: "en",
        };
        modifyRobot(args).then((res) => {
          if (!res) return;
          if (res.data.code === 0) {
            notification.open({
              message: "修改成功",
              duration: 3,
            });
            newData.splice(index, 1, { ...item, ...row });
          } else {
            notification.open({
              message: "修改失败",
              duration: 3,
            });
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const cancel = () => {
    setEditingKey("");
  };

  const handleDelete = (record) => {
    const dataTemp = [...data];
    console.log(".........delete" + record.id);
    deleteRobot(record.id).then((res) => {
      if (!res) return;
      if (res.data.code === 0) {
        notification.open({
          message: "删除成功",
          duration: 3,
        });
        setData(dataTemp.filter((item) => item.key !== record.key));
      } else {
        notification.open({
          message: "删除失败",
          duration: 3,
        });
      }
    });
  };

  const handleAdd = () => {
    // open modal
    setVisible(true);
  };

  const handleCancel = (e) => {
    console.log(e);
    setVisible(false);
  };

  const onOk = (newRow) => {
    notification.open({ message: SUCCEED, duration: 3 });
    getRobotsList();
  };

  const components = {
    body: {
      cell: EditableCell,
    },
  };
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Button
        onClick={handleAdd}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        添加机器人
      </Button>

      <h3>双击进入编辑页面</h3>

      <Table
        components={components}
        bordered
        dataSource={data}
        columns={mergedColumns}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              props.history.push({ pathname: `/robot/${record.id}` });
            },
          };
        }}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />

      <ModalWithForm visible={visible} onCancel={handleCancel} onOk={onOk} />
    </Form>
  );
};

class ModalWithForm extends Component {
  handleOk = (values) => {
    let args = {
      name: values.name,
      type: values.type,
      description: values.description,
    };

    createRobot(args).then((res) => {
      if (!res) return;
      const response = res.data.data.result;
      console.log(".......saved" + JSON.stringify(response));
      this.props.onOk(response);
      this.props.onCancel();
    });
  };

  render() {
    const { TextArea } = Input;
    return (
      <Modal
        title="添加机器人"
        visible={this.props.visible}
        footer={null}
        onCancel={() => this.props.onCancel()}
      >
        <Form onFinish={this.handleOk}>
          <Form.Item
            label="产品类型"
            name="type"
            rules={[{ required: true, message: "请输入产品类型" }]}
            initialValue={1}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="产品名称"
            name="name"
            rules={[{ required: true, message: "请填写产品名称" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="产品描述"
            name="description"
            rules={[{ required: true, message: "请填写产品描述" }]}
          >
            <TextArea
              rows={3}
              onChange={this.handModalInputDspt}
              placeholder="产品描述"
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit">{ADD}</Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default withRouter(Robots);
