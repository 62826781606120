import axios from "axios";
import { CREATE, READ, UPDATE, DEL } from "../utils/constants";
import store from "../store";
import { openLoginModal } from "../store/actionCreators";
import { notification } from "antd";

// 配置全局的参数
axios.defaults.timeout = 60000;
axios.defaults.responseType = "json";
axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";

export const shouldLogin = (promise) => {
  return promise.catch((err) => {
    console.log(err.response);
    if (err.response.status === 401) {
      notification.open({
        message: "账号或密码错误!",
        duration: 3,
      });
      store.dispatch(openLoginModal(true));
    }
    // if (err.response.code === 10002) {
    //   notification.open({
    //     message: "账号或密码错误!",
    //     duration: 3,
    //   });
    //   store.dispatch(openLoginModal(true));
    // }
  });
};

export const getMenuList = () => {
  console.log("======111111===getMenuList: ");

  return axios.get("website/menus/list");
};

export const getRobots = () => {
  return axios.get("/website/robots/list", {
    params: {
      lang: "en",
    },
  });
};

export const createRobot = (args) => {
  return shouldLogin(axios.post("/website/robots/create", args));
};
export const modifyRobot = (args) => {
  return shouldLogin(axios.put("/website/robots/modify", args));
};

export const deleteRobot = (id) => {
  return shouldLogin(
    axios.post("/website/robots/delete", {
      id: id,
    })
  );
};
//2021-7-3 资料管理

//获取资料
export const getDataManage = () => {
  return axios.get("/website/compressedPackages/list")
}

//删除资料
export const deleteDataManage = (payload) => {
  return shouldLogin(axios.post("/website/compressedPackages/delete", payload));
}

//上传资料
export const uploadDataManage = (postData) => {
  return shouldLogin(
    axios({
      method: "post",
      url: "/website/compressedPackages/upload",
      data: postData,
      headers: { "Content-Type": "multipart/form-data" },
    })
  );
}

export const getVideos = () => {
  return axios.get("/website/videos/list");
};
export const createVideo = (postData) => {
  return shouldLogin(
    axios({
      method: "post",
      url: "/website/videos/upload",
      data: postData,
      headers: { "Content-Type": "multipart/form-data" },
    })
  );
};
export const updateVideo = (id, payload) => {
  return shouldLogin(axios.patch(`/website/videos/${id}`, payload));
};

export const deleteVideo = (payload) => {
  return shouldLogin(axios.post("/website/videos/delete", payload));
};

export const getPicturesByMenu = (menupage) => {
  return axios(`/website/menus/${menupage}/pictures`);
};

export const updatePicture = (menupage, formData) => {
  return shouldLogin(
    axios({
      url: `/website/menus/${menupage}/pictures/`,
      method: "PUT",
      data: formData,
      headers: { "Content-Type": "application/json" },
    })
  );
};

export const deletePicture = (menupage, id) => {
  return shouldLogin(axios.delete(`/website/menus/${menupage}/pictures/${id}`));
};

export const crudNews = (type, data, id) => {
  let URI = "website/informations";
  let URID = `website/informations/${id}`;
  switch (type) {
    case READ:
      return axios.get(URI);
    case UPDATE:
      return shouldLogin(axios.patch(URID, data));
    case DEL:
      return shouldLogin(axios.delete(URID));
    case CREATE:
      return shouldLogin(axios.post(URI, data));
    default:
      return axios.get(URI);
  }
};

export const readNewsList = (lang) => {
  //这里是全部 以后要改
  let URI = "website/informations?page=1&size=1000";
  let _params = lang === "en" ? "en" : "";
  return axios.get(URI, {
    params: {
      lang: _params,
    },
  });
};

// id here means news id
export const crudNewsPics = (type, data, id, pictureId) => {
  const URI = `/website/informations/${id}/pictures`;
  const URID = `/website/informations/${id}/pictures/${pictureId}`;

  switch (type) {
    case READ:
      return axios.get(URI);
    case UPDATE:
      return shouldLogin(axios.put(URI, data));
    case DEL:
      return shouldLogin(axios.delete(URID));
    case CREATE:
      // id file type sid lang, int file int int string formdata
      return shouldLogin(axios.post(URI, data));
    default:
      return axios.get(URI);
  }
};

//lang title content
export const newNewsPage = (data) => {
  return shouldLogin(axios.post("/website/informations", data));
};

export const updateNewsPage = (id, data) => {
  return shouldLogin(axios.patch(`/website/informations/${id}`, data));
};

export const getPreviousContent = (id) => {
  return axios.get(`/website/informations/${id}`);
};

export const userLogin = () => {
  return axios.post("/website/user/logout");
};
// Promise.reject()
//   .catch(() => console.log("rejection is caught in first `catch`"))
//   .then(() => console.log("`catch` returns fulfilled  promise so `then` is executed"))
//   .catch(() => console.log("this won't be executed"))
// https://stackoverflow.com/a/45270490/9098558

export const readMediaReportList = (lang) => {
  let URI = "website/mediareports/";
  let _params = lang === "en" ? "en" : "zh";
  return axios.get(URI, {
    params: {
      lang: _params,
    },
  });
};

export const crudMediaReportPics = (type, data, id, pictureId) => {
  const URI = `/website/mediareports/${id}/pictures`;
  const URID = `/website/mediareports/${id}/pictures/${pictureId}`;

  switch (type) {
    case READ:
      return axios.get(URI);
    case UPDATE:
      return shouldLogin(axios.put(URI, data));
    case DEL:
      return shouldLogin(axios.delete(URID));
    case CREATE:
      // id file type sid lang, int file int int string formdata
      return shouldLogin(axios.post(URI, data));
    default:
      return axios.get(URI);
  }
};

export const crudMediaReport = (type, data, id) => {
  let URI = "website/mediareports";
  let URID = `website/mediareports/${id}`;
  switch (type) {
    case READ:
      return axios.get(URI);
    case UPDATE:
      return shouldLogin(axios.patch(URID, data));
    case DEL:
      return shouldLogin(axios.delete(URID));
    case CREATE:
      return shouldLogin(axios.post(URI, data));
    default:
      return axios.get(URI);
  }
};

export const newMediaReportPage = (data) => {
  return shouldLogin(axios.post("/website/mediareports", data));
};
export const updateMediaReportPage = (id, data) => {
  return shouldLogin(axios.put(`/website/mediareports/${id}`, data));
};
export const getMediaReportPage = (id) => {
  return axios.get(`/website/mediareports/${id}`);
};
