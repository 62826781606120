import axios from "axios";
import { notification } from "antd";
import store from "../store";
import { hideLoginModal } from "../store/actionCreators";
import { shouldLogin } from "./request";
axios.defaults.withCredentials = true;

async function http(promise) {
  await promise;
}

const userLogin = (username, password) => {
  return shouldLogin(
    axios
      .post("/website/user/login", {
        account: username,
        password: password,
      })
      .then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          notification.open({
            message: "登录成功!",
            duration: 3,
          });
          store.dispatch(hideLoginModal(false));
          window.location.pathname = "home";
        }
        // else {
        //   notification.open({
        //     message: "账号或密码错误!",
        //     duration: 3,
        //   });
        // }
      })
  );
};

const menusModify = (newData, row, index, item) => {
  // let jsessionid = cookies.get('jsessionid')
  return shouldLogin(
    axios.post("/website/menus/modify", {
      id: newData[index].id,
      name: row.name,
      lang: "en",
      langName: row.en,
      // en: row.en,
      pid: row.pid,
      url: row.url,
      headers: {
        // 'Cookie': jsessionid,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
  );
};

const uploadImage = (postdata, cb) => {
  return shouldLogin(
    axios({
      method: "post",
      url: "/website/pictures/upload",
      data: postdata,
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress(progressEvent) {
        if (progressEvent.lengthComputable && cb) {
          cb(progressEvent);
        }
      },
    })
  );
};

const putMenuImage = (postdata, cb) => {
  return axios({
    method: "put",
    // url: '/website/menus/${mennuId}/pictures/',
    url: "/website/menus/1/pictures/",
    data: postdata,
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress(progressEvent) {
      if (progressEvent.lengthComputable && cb) {
        cb(progressEvent);
      }
    },
  });
};

export const replaceImage = (postdata, cb) => {
  return axios({
    method: "post",
    url: "/website/pictures/upload",
    data: postdata,
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress(progressEvent) {
      if (progressEvent.lengthComputable && cb) {
        cb(progressEvent);
      }
    },
  });
};

export { http, userLogin, menusModify, uploadImage, putMenuImage };
