const defaultState = {
    show_login_modal: false
}

const rootReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "OPEN_LOGIN_MODAL":
            return { show_login_modal: action.data }
        case "HIDE_LOGIN_MODAL":
            return { show_login_modal: action.data }
        default:
            return state;
    }
}
export default rootReducer