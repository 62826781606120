
import React, { Component } from 'react'
import { Table, Popconfirm, notification } from 'antd';
import { createVideo, getVideos, updateVideo, deleteVideo } from "../../service/request"
import { baseUrl } from '../../utils/constants'
  
class Video extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }

    getVideos().then(res => {
      if (!res) return
      const results = res.data.data.result.map((row, index) => ({
        key: index,
        name: row.name,
        dataIndex: index,
        id: row.id,
        pid: row.pid,
        home: row.home,
        file: row.file,
        path: row.path
      }))

      this.setState({
        data: results,
      })
    }).catch(function (error) {
      // console.log(error)
    });

    this.columns = [
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: '首页展示',
        dataIndex: 'home',
        key: 'home'
      },
      {
        title: 'id',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'file',
        dataIndex: 'file',
        key: 'file'
      },
      {
        title: '操作',
        dataIndex: 'operation',
        render: (text, record) => {
          return (
            <span>
              <a
                style={{
                  marginRight: 8,
                }}
                href={baseUrl + "/" + record.path + record.file}
              >
                预览
                </a>
              <Popconfirm title="确定在首页展示?"
                onConfirm={() => this.handleModify(record)}>
                <a
                  style={{
                    marginRight: 8,
                  }}>
                  展示
                </a>
              </Popconfirm>
              <Popconfirm
                title="确定删除?" onConfirm={() => this.handleDelete(record)}>
                <a>删除</a>
              </Popconfirm>
              <a></a>
            </span>
          )
        }
      }
    ]
  }
  handleDelete = record => {
    const data = [...this.state.data];
    let payload = {
      id: record.id,
    }
    deleteVideo(payload).then(res => {
      if (!res) return
      if (res.data.code === 0) {
        this.setState({
          data: data.filter(item => item.id !== record.id),
        })
        notification.open({
          message: '删除成功',
          duration: 3,
        })
      }
    })
  }

  handleModify = record => {
    const home = record.home === 1 ? 0 : 1;
    let payload = {
      home: home
    }
    updateVideo(record.id, payload).then(res => {
      if (!res) return
      if (res.data.code === 0) {
        const newData = [...this.state.data];
        const index = newData.findIndex(item => item.id === record.id);

        newData[index].home = home

        this.setState({ data: newData })
        notification.open({
          message: '修改成功',
          duration: 3,
        })
      }
    })
  }

  postVideoToServer() {
    let input = document.forms['videoform'].input1
    let postdata = new FormData()
    postdata.append('name', "test")
    postdata.append('file', input.files[0])

    console.log(input.files[0]);

    if (input.files[0] === undefined) {
      notification.open({
        message: '请选择文件！',
        duration: 1,
      })
    } else {

      createVideo(postdata).then(res => {
        if (!res) return
        if (res.data.msg === "success") {
          notification.open({
            message: '上传成功',
            duration: 3,
          })
          const newRow = res.data.data.result
          this.setState({
            data: [...this.state.data, newRow]
          })
        }
      })
        .catch(function (response) {
          console.log(response);
        });

      // TODO: read more about different of fetch n axios!
      // fetch("/website/videos/upload", 
      //     { 
      //       method: 'post',
      //       body: postdata 
      //     })
    }
  }

  handleSubmit = e => {
    const { dispatch, form } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      // values 是form表单里的参数
      // 点击按钮后，将表单提交给后台
      dispatch({
        type: 'mymodel/submitFormData',
        payload: values,
      });
    });
  };

  render() {
    const { data } = this.state
    return (
      <div>
        <Table columns={this.columns} dataSource={data} />
        <input style={{ opacity: 1 }} onClick={() => {
          this.postVideoToServer()
        }} form="UpdataImg" id="Button1" type="submit" value="上传视频" />

        <form onSubmit={this.handleSubmit.bind(this)}
          name="videoform">
          {/*accept限定图片上传格式，指定id，方便formData上传时获取file的数据*/}
          <input id="imgUrl" name="input1" type="file"
            ref="files" />
        </form>

      </div>

    )
  }
}
export default Video