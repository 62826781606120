// Upload modal, //dynamic formitem with data, handleSubmit
import React, { useState } from 'react'
import { UPLOAD, ADD, SID_PLEASE, SELECT, ZH, EN, SELECT_LANG, TYPE_PLEASE, LANG_PLEASE } from '../../utils/constants'
import { Input, Upload, Button, Modal, Form, InputNumber, Select } from 'antd';
const { TextArea } = Input;


function UploadModal(props) {
    const { children, data, ifUploadFile } = props
    const [fileList, setFileList] = useState([])
    const [visible, setVisible] = useState(false)
    const { Option } = Select;
    const form = Form.useForm()
    const handleChange = (info) => {
        setFileList(info.fileList);
    }
    const uploadprops = {
        beforeUpload: () => false,
        onChange: handleChange,
        multiple: false,
    };
    const handleVisible = visible => {
        setVisible(visible)
    }
    const handleOk = (values) => {
        // props.form.validateFields((err, values) => {
        // if (err) return;
        if (ifUploadFile) {
            console.log('fileListInModal', fileList);
            props.handleSubmit(values, fileList)
        } else {
            props.handleSubmit(values)
        }
        setVisible(false)
        // })
    }

    const selectTag = item => {
        switch (item.type) {
            case 'int':
                return <InputNumber />;
            case 'string':
                return <Input />;
            case 'select':
                return (
                    <Select >
                        {item.options.map((option, index) => {
                            return (<Option key={index} value={option.key}>{option.value}</Option>)
                        })
                        }
                    </Select>)
            case 'number':
                return <InputNumber />
            case 'text':
                return <TextArea rows={3} />
            default:
                return <Input />
        }
    }

    return (
        <section>
            {children ?
                React.cloneElement(children, { onClick: () => handleVisible(true) })
                : null
            }
            <Modal
                visible={visible}
                // onOk={handleOk}
                onCancel={() => handleVisible(false)}
                footer={null}
            >
                <Form onFinish={handleOk}>
                    {data.map((item, index) => {
                        return (
                            <Form.Item label={item.label}
                                initialValue={item.value}
                                name={item.name}
                                rules={[{ required: item.required, message: item.message }]}
                            >
                                {selectTag(item)}
                            </Form.Item>
                        )
                    })}
                    {/* then the static submit button */}
                    {ifUploadFile ?
                        <Upload {...uploadprops}
                            fileList={fileList}
                            beforeUpload={() => false}
                            onChange={handleChange}
                        >
                            <Button>
                                {SELECT}
                            </Button>
                        </Upload>
                        : null}
                    <Button htmlType="submit">{UPLOAD}</Button>
                </Form>
                <div id="footer"></div>
            </Modal>
        </section >
    )
}

export default React.memo(UploadModal)

//mock data
// const data = [
//     {
//         feild: '',
//         text: '',
//         required: 'true',
//         type: 'int',
//         value: 100   
//     },
//     {
//         feild: '',
//         text: '',
//         required: 'true',
//         type: 'select',
//         options: ['en', 'zh'],
//         value: 'zh'
//     },
//     {
//         feild: '',
//         text: '',
//         required: 'true',
//         type: 'number',
//         value: 100
//     },
//     {
//         feild: '',
//         text: '',
//         required: 'true',
//         type: 'string',
//         value: 100
//     },
// ]