export const SUBMIT = "提交"
export const SELECT = "选择"
export const ADD = "添加"
export const UPLOAD = "上传"
export const REPLACE = "替换"
export const DELETE = "删除"
export const ZH = '中文'
export const EN = '英文'
export const SELECT_LANG = "请选择语言"
const SID_PLEASE = "请顺序填写sid，相同sid的图片会被替换"
const TYPE_PLEASE = 'type1为题图，type2为详情图'
const LANG_PLEASE = '请选择图片为中文版或者英文版'
export {
    SID_PLEASE,
    TYPE_PLEASE,
    LANG_PLEASE
}
export const READ = "get";
export const UPDATE = 'update';
export const DEL = 'delete';
export const CREATE = 'create'
export const EDIT = '编辑'
export const CANCEL = '取消'
export const SAVE = '保存'
export const baseUrl = "https://ai.rmbot.cn"
export const baseUrlDev = "http://192.168.1.158:9011"
export const baseUrlHttp1  = "http://www.reeman.cn"
export const SUCCEED = "添加成功"
