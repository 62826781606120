import React, { Component, useEffect } from 'react'
import axios from 'axios'
import { Input, Upload, Button, Modal, Form, InputNumber, Select } from 'antd';
import { UPLOAD, ADD, SID_PLEASE, SELECT, ZH, EN, SELECT_LANG, TYPE_PLEASE, LANG_PLEASE } from '../../utils/constants'
import { configConsumerProps } from 'antd/lib/config-provider';

const { Option } = Select;

class UploadModal extends Component {

    state = {
        fileList: []
    }

    handleSubmit = (values) => {
        // confirm todo
        let formData = new FormData();
        formData.append('robotid', this.props.robotid)
        formData.append('type', values.type) // default 2
        formData.append('sid', values.sid)
        formData.append('lang', values.lang)
        formData.append("file", this.state.fileList[0].originFileObj)
        axios({
            url: `/website/robots/${this.props.robotid}/pictures`,
            method: 'PUT',
            data: formData,
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => {
                // const response = res.data.data.result
                // console.log(".......saved" + JSON.stringify(response));
                // let newRow = res.data.data.result
                // this.props.(newRow)
                this.props.updateDataList()
                // 会刷新modal组件影响性能
                this.setState({ fileList: [] })
                this.props.uploadSucceed()
            })
    };

    handleChange = ({ fileList }) => {
        console.log('fileList', fileList);
        this.setState({ fileList });
    };

    render() {
        const props = {
            onChange: this.handleChange,
            multiple: false,
        };
        return (
            <Modal
                title={ADD}
                visible={this.props.visible}
                closable={false}
                footer={null}
            >
                <Form onFinish={this.handleSubmit}>
                    <Form.Item label={SID_PLEASE} name="sid"
                        rules={[{ required: true, message: { SID_PLEASE }, },]}
                    >

                        <InputNumber min={1} max={15}/>
                    </Form.Item>
                    <Form.Item label={TYPE_PLEASE} name="type"
                        rules={[{ required: true, message: { TYPE_PLEASE }, },]}
                    >
                        <InputNumber min={1} max={2}/>
                    </Form.Item>

                    <Form.Item
                        name="lang"
                        label={LANG_PLEASE}
                        rules={[{ required: true, message: 'Please select your lang', },]}
                    >
                        < Select placeholder={SELECT_LANG} >
                            <Option value="zh">{ZH}</Option>
                            <Option value="en">{EN}</Option>
                        </Select>
                    </Form.Item>

                    <Upload {...props}
                        fileList={this.state.fileList}
                        beforeUpload={() => false}
                    >
                        <Button>
                            {SELECT}
                        </Button>
                    </Upload>
                    <Form.Item>
                    <Button htmlType="submit" >
                        {UPLOAD}
                    </Button>
                    </Form.Item>
                </Form>
            </Modal >
        )
    }
}


export default React.memo(UploadModal)