import React from "react";
import {
  BrowserRouter,
  Router,
  Route,
  Switch,
  Redirect,
  HashRouter,
} from "react-router-dom";
import LoginMainPage from "./containers/login";
import Home from "./containers/home";
import robotType from "./containers/robotType";
import loadable from "./utils/loadable";
import Video from "./containers/video";
import Robots from "./containers/robots";
import RobotPage from "./containers/robots/robotpage";
import News from "./containers/news";
import Pictures from "./containers/pictures";
import Header from "./components/header";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import NewsPage from "./containers/news/newspage";
import MediaReports from "./containers/mediareports";
import MediaReportPage from "./containers/mediareports/mediareportpage";
import Download from './containers/dataDownload/index'
import { connect } from "react-redux";

const Login = loadable(() => import("./views/Login"));

class AppRouter extends React.Component {
  state = {
    visible: true,
  };

  onOk = () => {
    // const temp = this.state.visible
    // this.setState({ visible: !temp })
  };

  render() {
    const { showLogin } = this.props;

    return (
      <>
        <BrowserRouter>
          <Login
            // visible={this.state.visible}
            onOk={this.onOk}
            visible={showLogin}
          // visible={true}
          />
          <Header
            // login={this.state.visible}
            login={showLogin}
            onOk={this.onOk}
          />
          <Switch>
            {/* <Route exact path="/" component={Home} /> */}
            <Route exact path="/" component={LoginMainPage} />
            {/* <Route path='/robotypes' component={robotType} /> */}
            <Route path="/home" component={Home} />
            <Route path="/video" component={Video} />
            <Route path="/image" component={Pictures} />
            <Route path="/robots" component={Robots} />
            <Route path="/robot/:id" component={RobotPage} />
            <Route path="/news" component={News} />
            <Route path="/new/:id" component={NewsPage} />
            <Route path="/mediareports" component={MediaReports} />
            <Route path="/mediareport/:id" component={MediaReportPage} />
            <Route path="/download" component={Download} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}
const mapStateToProps = (state) => ({ showLogin: state.show_login_modal });

export default connect(mapStateToProps, null)(AppRouter);
