import "braft-editor/dist/index.css";
import React, { useEffect } from "react";
import BraftEditor from "braft-editor";
import { Form, Input, Button, Select, notification, DatePicker } from "antd";
import moment from "moment";
import { useState } from "react";
import { newNewsPage, updateNewsPage, getPreviousContent, updateMediaReportPage, newMediaReportPage, getMediaReportPage } from '../../service/request'
import { SELECT_LANG, ZH, EN } from '../../utils/constants'

const { Option } = Select;

function NewsEditor(props) {
    const title = localStorage.getItem("editorTitle");
    const media = localStorage.getItem("editorMedia");
    const author = localStorage.getItem("editorAuthor");
    const date = localStorage.getItem("editorDate");
    const url = localStorage.getItem("editorURL");
    const content = localStorage.getItem("editorContent");
    const [editorTitle, setEditorTitle] = useState(title)
    const [editorMedia, setEditorMedia] = useState(media)
    const [editorAuthor, setEditorAuthor] = useState(author)
    const [editorDate, setEditorDate] = useState(date)
    const [editorURL, setEditorURL] = useState(url)
    // const [editorTitle, setEditorTitle] = useState(title ? title : 'title');
    const [editorContent, setEditorContent] = useState(content);//hmtl content of editorState
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(content ? content : ""));//the editor object
    const [form] = Form.useForm();

    const id = props.match.params.id
    const [ifUpdate, setIfUpdate] = useState(id === 'new' ? false : true)
    const preview = () => {
        if (window.previewWindow) {
            window.previewWindow.close()
        }

        window.previewWindow = window.open()
        window.previewWindow.document.write(editorTitle + editorContent)
        window.previewWindow.document.close()
    }

    const controls = [
        "bold",
        "italic",
        "underline",
        "text-color",
        "separator",
        "link",
        "separator",
        "media",
        'clear',
    ];

    const extendControls = [
        {
            key: 'custom-button',
            type: 'button',
            text: '预览',
            onClick: preview
        }
    ]

    useEffect(() => {
        if(ifUpdate){
            getMediaReportPage(id).then((res) => {
                let ret = res.data.data.result
                if (ret.title) {
                    setIfUpdate(true)
                    setEditorTitle(ret.title)
                    setEditorContent(ret.content)
                    form.setFieldsValue({
                        title: ret.title,
                        media: ret.media,
                        author: ret.author,
                        date: moment(ret.date),
                        url: ret.url,
                        lang: ret.lang
                    });
                    setEditorState(BraftEditor.createEditorState(ret.content))
                }
            })
        }else{

        }
    }, [])

    const handleSaveLocal = () => {
        console.log("editorState" + editorState.toHTML());
        localStorage.setItem("editorTitle", editorTitle);
        localStorage.setItem("editorContent", editorState.toHTML())
    };

    let handleChange = (editorState) => {
        setEditorState(editorState)
        setEditorContent(editorState.toHTML())
    }

    const onFinish = (values) => {
        console.log("onFinish", JSON.stringify(values));
        let data = {}
        data.title = values.title
        data.content = editorContent
        data.lang = values.lang
        data.media = values.media
        data.author = values.author
        data.date = values.date
        data.url = values.url
        if (id === 'new') {
            newMediaReportPage(data).then(res => {
                notification.open({
                    message: '发布成功,3秒后返回',
                    duration: 3,
                })
                setTimeout(() => {
                    props.history.goBack()
                }, 3000)
            })
        } else {
            updateMediaReportPage(id, data).then(res => {
                notification.open({
                    message: '更新成功',
                    duration: 3,
                })
            })

        }
    }
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div
            style={{ padding: "2rem" }}
        >
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{
                    ["title"]: editorTitle
                }}
            >
                <Form.Item label="语言"
                    name="lang"
                    initialValue="zh"
                    rules={[{
                        required: true,
                        message: "请选择中英文",
                    }]}>

                    <Select placeholder={SELECT_LANG} >
                        <Option value="zh">{ZH}</Option>
                        <Option value="en">{EN}</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="文章标题"
                    name="title"
                    rules={[{
                        required: true,
                        message: "请输入标题",
                    }]}>
                    <Input
                        size="large"
                        name='title'
                        placeholder="请输入标题"
                        key={editorTitle}
                        // defaultValue={}
                        value={editorTitle}
                        // defaultValue={editorTitle}
                    />
                </Form.Item>
                <Form.Item label="媒体"
                    name="media"
                    rules={[{
                        required: true,
                        message: "请输入媒体名称",
                    }]}>
                    <Input
                        size="large"
                        name='media'
                        placeholder="请输入媒体名称"
                        key={editorMedia}
                        value={editorMedia}
                    />
                </Form.Item>
                <Form.Item label="作者"
                    name="author"
                    rules={[{
                        required: true,
                        message: "请输入作者",
                    }]}>
                    <Input
                        size="large"
                        name='author'
                        placeholder="请输入作者"
                        key={editorAuthor}
                        value={editorAuthor}
                    />
                </Form.Item>
                <Form.Item label="日期"
                    name="date"
                    rules={[{
                        required: true,
                        message: "请输入日期",
                    }]}>
                    <DatePicker size="large" name='date' key={editorDate} value={editorDate}></DatePicker>
                </Form.Item>
                <Form.Item label="统一资源定位器"
                    name="url"
                    rules={[{
                        required: true,
                        message: "请输入统一资源定位器,必须以http://或者https://开头",
                    }]}>
                    <Input
                        size="large"
                        name='url'
                        placeholder="请输入统一资源定位器,必须以http://或者https://开头"
                        key={editorURL}
                        value={editorURL}
                    />
                </Form.Item>
                <Form.Item>
                    
                </Form.Item>
                < Form.Item >
                    <Button size="large" type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
                {/* <div dangerouslySetInnerHTML={{ __html: html }}></div> */}
            </Form>
        </ div>
    )
}

export default NewsEditor;