import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Login from "../../views/Login";

class LoginMainPage extends Component {
  state = {};
  render() {
    return <Login></Login>;
  }
}

export default withRouter(LoginMainPage);
