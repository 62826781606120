import React, { Component } from 'react'
import { Table } from 'antd';
import  axios  from 'axios';


const columns = [
    {
        title : 'name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title : 'pid',
        dataIndex: 'pid',
        key : 'pid'
    },
    {
        title : 'id',
        dataIndex: 'id',
        // key : 'pid'
    }
]

class RobotType extends Component {
    constructor() {
        super()
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        axios    
        .get("/website/robot/types/list")
        .then( res => {
            // console.log("===got it"+JSON.stringify(response.data.data.result));
            this.setState({
                data: res.data.data.result
             })
            console.log("======is data updated"+JSON.stringify(res.data.data.result))
        })
        .catch(function (error) {
            console.log(error)
        });
    }
    render() {
        const { data } = this.state
        return(
            <div>
                <h3 id='basic'>机器人类型管理</h3>        
                <Table columns={columns} dataSource={data}/>
            </div>
        
        )
    }
}
export default RobotType