import React, { useState, useEffect } from "react";
import { Button, Input, InputNumber } from "antd";
import {
  ADD,
  READ,
  CREATE,
  DEL,
  UPDATE,
  REPLACE,
  SELECT,
  UPLOAD,
} from "../../utils/constants";
import { UploadOutlined } from "@ant-design/icons";
import { Table, Popconfirm, Form, Upload } from "antd";
import { withRouter } from "react-router-dom";
import { crudNews, crudNewsPics, readNewsList } from "../../service/request";
import { EDIT, DELETE, SAVE, CANCEL, baseUrl } from "../../utils/constants";

const EditableContext = React.createContext();
function EditableCell(props) {
  const getInput = () => {
    if (props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  const renderCell = () => {
    const { editing, dataIndex, title, record, children, ...restProps } = props;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
            initialValue={record[dataIndex]}
          >
            {getInput()}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>;
}
function News(props) {
  const [newsList, setNewsList] = useState([]);
  const [newsListEn, setNewsListEn] = useState([]);

  const [editingKey, setEditingKey] = useState("");
  const [fileList, setFileList] = useState([]);
  const getNewsList = () => {
    readNewsList().then((res) => {
      console.log(res);
      if (res.data) {
        let list = res.data.data.result.map((item) => {
          item.img = item.picturePath + item.picture;
          return item;
        });
        setNewsList(list);
        // this.setstate({
        //   totalpages: res.data.data.result.totalNumber,
        // });
      }
    });
    readNewsList("en").then((res) => {
      if (res.data) {
        let listEn = res.data.data.result.map((item) => {
          item.img = item.picturePath + item.picture;
          return item;
        });
        setNewsListEn(listEn);
      }
    });
  };

  useEffect(() => {
    getNewsList();
  }, []);
  const components = {
    body: {
      cell: EditableCell,
    },
  };

  const handleChange = ({ fileList }) => {
    console.log("fileList", fileList);
    setFileList(fileList);
  };

  const handleReplace = (e, record) => {
    let formData = new FormData();
    formData.append("file", fileList[0].originFileObj);
    crudNewsPics(UPDATE, formData, record.id).then((res) => {
      getNewsList();
    });
  };

  const prop = {
    onChange: handleChange,
    multiple: false,
  };

  const columnsAttr = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      editable: false,
    },
    {
      title: "title",
      dataIndex: "title",
      editable: true,
    },
    {
      title: "首页展示",
      dataIndex: "home",
      key: "home",
    },
    {
      title: "图片",
      dataIndex: "img",
      key: "img",
      render: (text, record) => {
        return (
          <>
            <img src={baseUrl + "/" + text} alt="请上传图片" />
            <span>
              <Upload {...prop} fileList={fileList} beforeUpload={() => false}>
                <Button>
                  <UploadOutlined /> {SELECT}
                </Button>
              </Upload>
              <Button onClick={(e) => handleReplace(e, record)}>
                {UPLOAD}
              </Button>
            </span>
          </>
        );
      },
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <EditableContext.Consumer>
              {(form) => (
                <a
                  href="javascript:;"
                  onClick={() => save(form, record.id)}
                  style={{
                    marginRight: 8,
                  }}
                >
                  {SAVE}
                </a>
              )}
            </EditableContext.Consumer>

            <Popconfirm title="Sure to cancel?" onConfirm={() => cancel()}>
              <a>{CANCEL}</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Popconfirm
              title="确定在首页展示?"
              onConfirm={() => handleDisplay(record)}
            >
              <a
                style={{
                  marginRight: 8,
                }}
              >
                展示
              </a>
            </Popconfirm>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record)}
            >
              <a disabled={editingKey !== ""}>{DELETE}</a>
            </Popconfirm>
          </span>
        );
      },
    },
    {},
  ];
  const isEditing = (record) => record.id === editingKey;
  const save = (form, id) => {
    form.validateFields((error, row) => {
      let data = {};
      data.title = row.title;
      data.content = row.content;
      data.titleEn = row.titleEn;
      data.contentEn = row.contentEn;
      crudNews(UPDATE, data, id).then((res) => {
        getNewsList();
        cancel();
      });
    });
  };
  const handleDisplay = (record) => {
    const home = record.home === 1 ? 0 : 1;
    let payload = {
      home: home,
    };
    crudNews(UPDATE, payload, record.id).then((res) => {
      if (!res) return;
      if (res.data.code === 0) {
        getNewsList();
      }
    });
  };
  const handleDelete = (record) => {
    crudNews(DEL, null, record.id).then((res) => {
      getNewsList();
    });
  };
  const cancel = () => {
    setEditingKey("");
  };
  const edit = (id) => {
    setEditingKey(id);
  };
  const columns = columnsAttr.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const handleSubmit = (values) => {
    let data = {};
    data.title = values.title;
    data.content = values.content;
    data.titleEn = values.titleEn;
    data.contentEn = values.contentEn;
    crudNews(CREATE, data).then((res) => {
      getNewsList();
    });
  };
  return (
    <div>
      <Button
        onClick={() => {
          props.history.push({ pathname: `/new/new` });
        }}
      >
        {ADD}
      </Button>
      <EditableContext.Provider value={props.form}>
        <h1>中文版管理</h1>
        <Table
          components={components}
          bordered
          dataSource={newsList}
          columns={columns}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                props.history.push({ pathname: `/new/${record.id}` });
              },
            };
          }}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
            // total: this.state.totalpages,
          }}
        />
        <h1>英文版管理</h1>
        <Table
          components={components}
          bordered
          dataSource={newsListEn}
          columns={columns}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                props.history.push({ pathname: `/new/${record.id}` });
              },
            };
          }}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </EditableContext.Provider>
    </div>
  );
}
export default React.memo(withRouter(News));
