import React, { Component } from "react";
import { Breadcrumb, Tabs, Button, notification } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { shouldLogin, userLogin } from "../../service/request";
const styles = {
  button: {
    display: "flex",
    justifyContent: "right",
    height: "100%",
    width: "100%",
    float: "right",
  },
  title: {
    display: "flex",
    left: "0",
    right: "0",
    height: "100%",
    width: "100%",
  },
};
class Header extends Component {
  callback = (key) => {
    this.props.history.push(`/${key}`); // < == router router v4
  };

  handleLogout = () => {
    shouldLogin(userLogin()).then((res) => {
      if (!res) return;
      if (res.data.code === 0) {
        notification.open({
          message: "登出成功!",
          duration: 3,
        });
      }
    });
  };
  render() {
    return (
      <div
        style={{
          display: window.location.pathname === "/" ? "none" : "block",
        }}
      >
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">导航菜单管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/robots">机器人管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/news">新闻管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/video">视频管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/image">图片管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/mediareports">媒体报道</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/download">资料管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Button className="float-right" onClick={this.handleLogout}>
              登出
            </Button>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    );
  }
}
export default withRouter(Header);
