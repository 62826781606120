import 'braft-editor/dist/index.css'
import React, { useEffect } from 'react'
import BraftEditor from 'braft-editor'
import { Form, Input, Button, Select, notification } from 'antd'
import { useState } from 'react'
import {
  newNewsPage,
  updateNewsPage,
  getPreviousContent,
} from '../../service/request'
import { SELECT_LANG, ZH, EN } from '../../utils/constants'

const { Option } = Select

function NewsEditor(props) {
  const title = localStorage.getItem('editorTitle')
  const content = localStorage.getItem('editorContent')
  const [editorTitle, setEditorTitle] = useState(title)
  // const [editorTitle, setEditorTitle] = useState(title ? title : 'title');
  const [editorContent, setEditorContent] = useState(content) //hmtl content of editorState
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState(content ? content : '')
  ) //the editor object
  const [form] = Form.useForm()

  const id = props.match.params.id
  const [ifUpdate, setIfUpdate] = useState(id === 'new' ? false : true)
  const preview = () => {
    if (window.previewWindow) {
      window.previewWindow.close()
    }

    window.previewWindow = window.open()
    window.previewWindow.document.write(editorTitle + editorContent)
    window.previewWindow.document.close()
  }

  const controls = [
    'bold',
    'italic',
    'underline',
    'text-color',
    'separator',
    'link',
    'separator',
    'media',
    'clear',
  ]

  const extendControls = [
    {
      key: 'custom-button',
      type: 'button',
      text: '预览',
      onClick: preview,
    },
  ]

  useEffect(() => {
    if (id === 'new') {
      return
    } else {
      getPreviousContent(id)
        .then((res) => {
          let ret = res.data.data.result
          console.log(res, 'news')
          if (ret.title) {
            setIfUpdate(true)
            setEditorTitle(ret.title)
            setEditorContent(ret.content)
            form.setFieldsValue({
              title: ret.title,
            })
            setEditorState(BraftEditor.createEditorState(ret.content))
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [])

  const handleSaveLocal = () => {
    localStorage.setItem('editorTitle', editorTitle)
    localStorage.setItem('editorContent', editorState.toHTML())
  }

  const encode = (str) => {
    let obj = new Object()
    for (let i = 0; i < str.length; i++) {
      let s = str[i]
      if (obj[s]) {
        obj[s]++
      } else {
        obj[s] = 1
      }
    }
    // return obj;
    let newStr = ''
    for (let key in obj) {
      newStr += key
      newStr += obj[key]
    }
    return newStr
  }

  let handleChange = (editorState) => {
    setEditorState(editorState)
    setEditorContent(editorState.toHTML())
    console.log('editorState.toHTML()', editorState.toHTML())
  }

  const onFinish = (values) => {
    console.log('editorContent', editorContent)
    let data = {}
    data.title = values.title
    data.content = editorContent
    data.lang = values.lang
    if (id === 'new') {
      newNewsPage(data).then((res) => {
        notification.open({
          message: '发布成功,3秒后返回',
          duration: 3,
        })
        setTimeout(() => {
          props.history.goBack()
        }, 3000)
      })
    } else {
      updateNewsPage(id, data).then((res) => {
        notification.open({
          message: '更新成功',
          duration: 3,
        })
      })
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div style={{ padding: '2rem' }}>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        initialValues={{
          ['title']: editorTitle,
        }}
      >
        <Form.Item
          label='语言'
          name='lang'
          initialValue='zh'
          rules={[
            {
              required: true,
              message: '请选择中英文',
            },
          ]}
        >
          <Select placeholder={SELECT_LANG}>
            <Option value='zh'>{ZH}</Option>
            <Option value='en'>{EN}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='文章标题'
          name='title'
          rules={[
            {
              required: true,
              message: '请输入标题',
            },
          ]}
        >
          <Input
            size='large'
            name='title'
            placeholder='请输入标题'
            key={editorTitle}
            // defaultValue={}
            value={editorTitle}
            // defaultValue={editorTitle}
            // onChange={(e) => {
            //   setEditorTitle(e.target.value)
            // }}
            onBlur={(e) => {
              setEditorTitle(e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item
          name='content'
          rules={[
            {
              required: true,
              message: '请输入正文内容',
            },
          ]}
          initialValue='content'
        >
          <div className='editor-wrapper' style={{ border: '1px solid grey' }}>
            <BraftEditor
              className='my-editor'
              // controls={controls}
              placeholder='请输入正文内容'
              value={editorState}
              extendControls={extendControls}
              onChange={handleChange}
            />
          </div>
        </Form.Item>
        <Form.Item></Form.Item>
        <Form.Item>
          <Button size='large' type='primary' htmlType='submit'>
            提交
          </Button>
          <Button size='large' type='info' onClick={handleSaveLocal}>
            缓存
          </Button>
        </Form.Item>
        {/* <div dangerouslySetInnerHTML={{ __html: html }}></div> */}
      </Form>
    </div>
  )
}

export default NewsEditor
